import { PrevButton, StepsTitle, NextButton } from "../componentsCheckout";
import { Wrapper } from "../componentsCheckout";
import { SelectCard } from "../../../components";
import Styles from "./PackageChoose.module.scss";
import react from "react";
import ReactGA from "react-ga";

const PackageChoose = ({
  zipLocation,
  setCurrent,
  data,
  setData,
  setSelected,
  selected,
  setFromPackageChoose,
  filteredPlans,
  setAddon,
  myRef,
}) => {
  const executeScroll = () => myRef.current.scrollIntoView({ block: "end" });
  ReactGA.initialize("G-V6QDG3Z3E9");

  return (
    <div className={Styles.packageChooseWrapper}>
      <div className={Styles.titlecontent}>
        <PrevButton onClick={() => setCurrent(0)} />
        <StepsTitle text="Choose Your Package" />
      </div>

      <Wrapper>
        <div
          onClick={() => {
            setData({
              ...data,
              packageType: "choice",
              addontype: null,
              deviceTypePrice: "10",
              addonTypePrice: "5",
            });
            setAddon(null);
          }}
        >
          <SelectCard
            zipLocation={zipLocation}
            id={1}
            showAddress={true}
            selected={selected}
            onSelect={setSelected}
            priceText="Wheels Choice"
            price={
              <>
                Starting from $89.99
                <span style={{ fontSize: "13px", fontWeight: "100" }}>
                  /30 Days
                </span>
              </>
            }
            arrText={[
              <>
                <b> Pick up</b>,<b> Repair</b>, and <b>Return </b> at service
                center.{" "}
              </>,

              <>
                1x/month <b>Preventative Maintenance</b> included**
              </>,
              <>Repair fees apply.</>,
            ]}
          />
        </div>
        {zipLocation?.Delivery === "Yes" && (
          <div
            onClick={() => {
              setData({
                ...data,
                packageType: "select",
                addontype: null,
                deviceTypePrice: "10",
                addonTypePrice: "5",
              });
              setAddon(null);
            }}
          >
            <SelectCard
              id={2}
              showAddress={false}
              selected={selected}
              onSelect={setSelected}
              priceText="Wheels Select"
              price={
                <>
                  Starting from $129.99
                  <span style={{ fontSize: "13px", fontWeight: "100" }}>
                    /30 Days
                  </span>
                </>
              }
              arrText={[
                <>
                  We come to your residence for <b>Delivery</b>, <b>Repairs</b>,
                  and <b>Picking up</b> the device when you're done.
                </>,
                <>
                  Unlimited <b>Preventative Maintenance</b> included**
                </>,
                <>1 free repair per month.</>,
              ]}
            />
          </div>
        )}
      </Wrapper>
      <NextButton
        htmlType="submit"
        type="primary"
        title="Continue"
        onClick={() => {
          window.fbq("track", "PackageChoose");
          ReactGA.pageview("PackageChoose");
          setData({
            ...data,
            plan: filteredPlans[1] || filteredPlans[0],
            planTemp: filteredPlans[1] || filteredPlans[0],
          });
          executeScroll();
          setCurrent(3);
        }}
        text={
          <>
            <div style={{ color: "#000" }}>
              {" "}
              Looking to lease multiple devices?
            </div>

            <a
              onClick={() => {
                setFromPackageChoose(true);
                setCurrent(4);
              }}
            >
              Get in contact with a Wheels Specialist.
            </a>
          </>
        }
        infoText="** Brake adjustments, flat tire, or electrical errors are considered maintenance. Excludes all other damages or repairs."
      />
    </div>
  );
};

export default react.memo(PackageChoose);
