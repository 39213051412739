import { Row, Col } from "antd";
import classNames from "classnames";
import { Typography } from "../../../../components";

import Styles from "./PaymentDetail.module.scss";

const RowPrice = ({ className, price, text, data }) => {
  return (
    <Row justify="space-between" className={className}>
      <Typography text={text} style={{ color: "#333" }} />
      <Typography text={price} style={{ color: "#333" }} />
    </Row>
  );
};

const PaymentDetail = ({
  className,
  plan,
  packageType,
  data,
  promoCodeData,
  URLparam,
}) => {
  const discountPercent = promoCodeData?.percent_off;

  const details = {
    yearly: {
      name: "Yearly",
      billingPeriod: "yearly",
      period: "per year",
    },
    threeMonth: {
      name: "3 Months",
      billingPeriod: "every 3 months",
      type: "threeMonth",
      period: "per month",
    },
    sixMonth: {
      name: "6 Months",
      billingPeriod: "every 6 months",
      period: "per month",
    },
    oneMonth: {
      name: "Monthly",
      billingPeriod: "monthly",
      period: "per month",
    },
    weekly: {
      name: "Weekly",
      billingPeriod: "weekly",
      period: "per week",
    },
  };

  if (!plan || !details[plan.type]) {
    // TODO(ibash) send a sentry error s.t. we know there's something missing
    // on the frontend
    return null;
  }

  let price;
  if (data?.plan?.amount_cents && data?.plan?.monthly_amount_cents) {
    if (data?.plan.type) {
      price = 1 * data?.plan.amount_cents;
    } else {
      price = 3 * data?.plan.monthly_amount_cents;
    }
  } else if (plan.type === "weekly") {
    price = 1 * plan.amount_cents;
  } else {
    price = 1 * plan.monthly_amount_cents;
  }

  price = (price / 100).toFixed(2);

  let paymentPeriod;
  if (details[data.plan.type].billingPeriod === "yearly") {
    paymentPeriod = "365";
  } else if (details[data.plan.type].type == "threeMonth") {
    paymentPeriod = "90";
  } else if (details[plan.type].billingPeriod === "every 6 months") {
    paymentPeriod = "180";
  } else if (details[plan.type].billingPeriod === "monthly") {
    paymentPeriod = "30";
  } else if (details[plan.type].billingPeriod === "weekly") {
    paymentPeriod = "7";
  }

  let paymentNameUbereats;
  let paymentPeriodUbereats;
  let deliveryUbereats = data.deliveryChoose === "delivery" ? 19.99 : 0;
  if (details[data.plan.type].name === "Monthly" && URLparam === "ubereats") {
    paymentNameUbereats = "UberEats - 30 Days";
    paymentPeriodUbereats = "Payment will be processed every 30 days.";
  } else if (
    details[data.plan.type].name === "Weekly" &&
    URLparam === "ubereats"
  ) {
    paymentNameUbereats = "UberEats - 7 Days";
    paymentPeriodUbereats = "Payment will be processed every 7 days.";
  }

  let paymentNameEdenred;
  let paymentPeriodEdenred;

  if (details[data.plan.type].name === "Monthly" && URLparam === "edenred") {
    paymentNameEdenred = "Edenred - 30 Days";
    paymentPeriodEdenred = "Payment will be processed every 30 days.";
  } else if (
    details[data.plan.type].name === "3 Months" &&
    URLparam === "edenred"
  ) {
    paymentNameEdenred = "Edenred - 90 Days";
    paymentPeriodEdenred = "Payment will be processed every 90 days.";
  } else if (
    details[data.plan.type].name === "6 Months" &&
    URLparam === "edenred"
  ) {
    paymentNameEdenred = "Edenred - 180 Days";
    paymentPeriodEdenred = "Payment will be processed every 180 days.";
  } else if (
    details[data.plan.type].name === "Yearly" &&
    URLparam === "edenred"
  ) {
    paymentNameEdenred = "Edenred - 360 Days";
    paymentPeriodEdenred = "Payment will be processed every 360 days.";
  }

  let devicePrice;
  let devicePriceSumma;
  let addonPrice;
  let addonPriceSumma;
  if (data.deviceTypePrice === "10") {
    devicePrice = `$ ${data.deviceTypePrice}.00 / ${
      data.deviceTypePrice * 3
    } Days`;
    addonPrice = `$ ${data.addonTypePrice}.00 / ${
      data.addonTypePrice * 6
    } Days`;

    devicePriceSumma = data.deviceTypePrice;
    addonPriceSumma = data.addonTypePrice;
  } else if (data.deviceTypePrice === "30") {
    devicePrice = `$ ${data.deviceTypePrice}.00 / ${
      data.deviceTypePrice * 3
    } Days`;
    addonPrice = `$ ${data.addonTypePrice}.00 / ${
      data.addonTypePrice * 6
    } Days`;

    devicePriceSumma = data.deviceTypePrice;
    addonPriceSumma = data.addonTypePrice;
  }

  const securityDeposit =
    URLparam === "google" || URLparam === "edenred" ? 0 : 50;
  const securityDepositUbereats = 25;

  let device = data.deviceType === "Wheel 3.0" ? devicePriceSumma : 0;
  let addon = data.addontype ? addonPriceSumma : 0;

  // URLparam === "google"

  // URLparam === "ubereats"

  // URLparam === "edenred"
  return (
    <div className={classNames(Styles.PaymentDetailWrapper, className)}>
      <Typography text="Order Detail" strong className={Styles.paymentTitle} />

      <Row justify="space-between" className={Styles.firstRowWrapper}>
        <Typography
          text={
            URLparam === "google"
              ? "Google - 30 Days"
              : URLparam === "ubereats"
              ? paymentNameUbereats
              : URLparam === "edenred"
              ? paymentNameEdenred
              : `Wheels ${
                  packageType.charAt(0).toUpperCase() + packageType.slice(1)
                }`
          }
          className={Styles.cardFirsRow}
        />
        <Typography text={`$ ${price}`} className={Styles.cardFirsRow} />
      </Row>
      <Typography
        text={
          URLparam === "ubereats"
            ? paymentPeriodUbereats
            : URLparam === "edenred"
            ? paymentPeriodEdenred
            : `Payment will be processed every ${paymentPeriod}  days.`
        }
        className={Styles.paymentInformation}
      />

      <Col>
        {URLparam !== "google" &&
          URLparam !== "ubereats" &&
          URLparam !== "edenred" && (
            <Row justify="space-between" align="bottom">
              <Row className={Styles.device}>
                <Typography text="Device:" className={Styles.color} />
                <Typography text={data.deviceType} className={Styles.color} />
              </Row>
              <Row>
                <Typography
                  text={data.deviceType === "Wheel 3.0" && devicePrice}
                  className={Styles.cargoPrice}
                />
              </Row>
            </Row>
          )}
        <Row justify="space-between" align="bottom">
          {data.addontype && (
            <Row className={Styles.addOns}>
              <Typography text="Add-ons:" className={Styles.color} />
              <Typography text="Cargo Rack" className={Styles.color} />
            </Row>
          )}
          <Row>
            <Typography
              text={data.addontype ? addonPrice : null}
              className={Styles.cargoPrice}
            />
          </Row>
        </Row>

        {URLparam === "ubereats" && data.deliveryChoose === "delivery" && (
          <RowPrice
            text="Delivery"
            price="$19.99"
            className={classNames(Styles.security, Styles.color)}
          />
        )}

        {discountPercent && (
          <RowPrice
            text="Discount"
            price={discountPercent ? `${discountPercent} %` : "-"}
            className={classNames(Styles.discount, Styles.color)}
          />
        )}
        {URLparam !== "google" && URLparam !== "edenred" && (
          <RowPrice
            text="Security Deposit"
            price={URLparam === "ubereats" ? "$25.00" : "$50.00"}
            className={classNames(Styles.security, Styles.color)}
          />
        )}

        {URLparam === "ubereats" ? (
          <RowPrice
            text="Total"
            price={
              discountPercent
                ? `$ ${(
                    securityDepositUbereats +
                    (+deliveryUbereats +
                      +price -
                      ((+deliveryUbereats + +price) * +discountPercent) / 100)
                  ).toFixed(2)}`
                : `$ ${(
                    securityDepositUbereats +
                    +deliveryUbereats +
                    +price
                  ).toFixed(2)}`
            }
            className={Styles.total}
          />
        ) : (
          <RowPrice
            text="Total"
            // price={`$ ${securityDeposit + +addon + +device + +price}`}
            price={
              discountPercent
                ? `$ ${(
                    securityDeposit +
                    (+addon +
                      +device +
                      +price -
                      ((+addon + +device + +price) * +discountPercent) / 100)
                  ).toFixed(2)}`
                : `$ ${(securityDeposit + +addon + +device + +price).toFixed(
                    2
                  )}`
            }
            className={Styles.total}
          />
        )}
      </Col>
    </div>
  );
};

export default PaymentDetail;
