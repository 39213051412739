export function customerReferralId() {
  return (window.Rewardful && window.Rewardful.referral) || null;
}

export function getRewardfulCoupon() {
  return (window.Rewardful && window.Rewardful.coupon) || null;
}

export const getPostalCode = async (placeId) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line prefer-promise-reject-errors
    if (!placeId) reject("placeId not provided");

    try {
      new window.google.maps.places.PlacesService(
        document.createElement("div")
      ).getDetails(
        {
          placeId,
          fields: ["address_components"],
        },
        (details) => {
          let postcode = null;
          // eslint-disable-next-line no-unused-expressions
          details?.address_components?.forEach((entry) => {
            if (entry.types?.[0] === "postal_code") {
              postcode = entry.long_name;
            }
          });
          return resolve(postcode);
        }
      );
    } catch (e) {
      reject(e);
    }
  });
export default getPostalCode;
