import { Typography } from "antd";

const { Text } = Typography;

const CustomTypography = ({ text, className, ...rest }) => {
  return (
    <Text {...rest} className={className}>
      {text}
    </Text>
  );
};

export default CustomTypography;
