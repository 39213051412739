import Styles from "./CheckPhone.module.scss";
import { StepsTitle } from "../componentsCheckout";
import { Typography } from "../../../components";
import { Wrapper, PrevButton, NextButton } from "../componentsCheckout";
import ReactCodeInput from "react-code-input";
import { message } from "antd";
import api from "../../../api";
import { useState } from "react";
import ReactGA from "react-ga";

const CheckPhone = ({ setCurrent, data, setData, URLparam, filteredPlans }) => {
  ReactGA.initialize("G-V6QDG3Z3E9");
  const [verification, setVerification] = useState();
  const [hasPromoCodeError, setHasPromoCodeError] = useState();
  const [count, setCount] = useState(0);

  const sendCode = () => {
    api.triggerPhoneNumberValidation(`+${data.phoneNumber}`);

    message.success({
      content: "Code Resent",
      className: "custom-class",
      style: {
        marginTop: "5vh",
      },
      duration: 5,
    });
  };

  async function check1() {
    const promoCodeResponse = await api.verifyPhoneValidation(
      `+${data.phoneNumber}`,
      String(verification)
    );

    setHasPromoCodeError(true);
    setTimeout(() => {
      setHasPromoCodeError(promoCodeResponse.success);
      const canProceed =
        promoCodeResponse.success ||
        process.env.REACT_APP_PHONE_CHECK === "PHONE_CHECK";

      if (canProceed) {
        window.fbq("track", "CompleteRegistration");
        ReactGA.pageview("CompleteRegistration");
        URLparam === "google"
          ? setCurrent(5)
          : URLparam === "ubereats" || URLparam === "edenred"
          ? setCurrent(3)
          : setCurrent(2);
        URLparam === "edenred"
          ? setData({
              ...data,
              plan:
                filteredPlans[3] ||
                filteredPlans[2] ||
                filteredPlans[1] ||
                filteredPlans[0],
              planTemp:
                filteredPlans[3] ||
                filteredPlans[2] ||
                filteredPlans[1] ||
                filteredPlans[0],
              user: promoCodeResponse,
            })
          : setData({ ...data, user: promoCodeResponse });
      } else {
        message.error({
          content: "Invalid code !",
          className: "custom-class",
          style: {
            marginTop: "5vh",
          },
          duration: 5,
        });
      }
      setVerification("");
      setCount(0);
    }, 500);
  }

  if (verification?.length === 4 && count === 0) {
    setCount(count + 1);
    check1();
  }

  return (
    <div className={Styles.CheckPhoneWrapper}>
      <div className={Styles.titlecontent}>
        <PrevButton
          onClick={() => {
            setCurrent(0);
            setData({ ...data, phoneNumber: data.phoneNumber });
          }}
        />
        <StepsTitle text="Check Your Phone" />
      </div>
      <Wrapper>
        <Typography
          text={`We sent a 4-digit security code to +${data.phoneNumber}.`}
        />
        <div className={Styles.inputNumbersWrapper}>
          <ReactCodeInput
            className={Styles.inputNumbers}
            type="number"
            value={verification}
            fields={4}
            inputMode="numeric"
            name="number-inputs"
            onChange={(e) => {
              setVerification(e);
            }}
          />
        </div>
      </Wrapper>
      <NextButton
        htmlType="submit"
        type="primary"
        disabled={verification?.length === 4}
        loading={hasPromoCodeError}
        // onClick={() => check1()}
        title="Continue"
        text={<a onClick={sendCode}>Resend code</a>}
      />
    </div>
  );
};

export default CheckPhone;
