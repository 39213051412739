import { useState, useEffect, useRef } from "react";
import {
  CheckPhone,
  UserInformation,
  PackageChoose,
  RentalTerm,
  GetInTouch,
  Payment,
  Pickup,
  InValidZipCode,
  Congratulation,
  Delivery,
} from "../chekoutSteps";
import TemporaryLastStep from "../chekoutSteps/TemporaryLastStep";

const CheckoutContent = ({
  setCurrent,
  current,
  plans,
  filteredPlans,
  basePrice,
  data,
  setData,
  summary,
  zipLocation,
  setZipLocation,
}) => {
  const myRef = useRef(null);

  const [allPlans, setAllPlans] = useState();

  const [zipLocation1, setZipLocation1] = useState();
  const [fromPackageChoose, setFromPackageChoose] = useState(true);

  const [selected, setSelected] = useState(1);
  const [term, setTerm] = useState(1);
  const [device, setDevice] = useState(1);
  const [addon, setAddon] = useState(null);
  const [delivery, setDelivery] = useState(1);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const URLparameter = urlParams.get("rental_type");
  const URLparam = URLparameter?.toLowerCase();

  // console.log("location", URLparam);

  const steps = [
    {
      title: "User Information",
      content: (
        <UserInformation
          setCurrent={setCurrent}
          setData={setData}
          data={data}
          setZipLocation={setZipLocation}
          filteredPlans={filteredPlans}
          URLparam={URLparam}
        />
      ),
      id: 0,
    },
    {
      title: "Check your phone",
      content: (
        <CheckPhone
          setCurrent={setCurrent}
          setData={setData}
          data={data}
          URLparam={URLparam}
          filteredPlans={filteredPlans}
        />
      ),
      id: 1,
    },
    {
      title: "Choose your package",
      content: (
        <PackageChoose
          zipLocation={zipLocation}
          setCurrent={setCurrent}
          data={data}
          selected={selected}
          setSelected={setSelected}
          setData={setData}
          setFromPackageChoose={setFromPackageChoose}
          filteredPlans={filteredPlans}
          setAddon={setAddon}
          myRef={myRef}
        />
      ),
      id: 2,
    },
    {
      title: "Rental Term",
      content: (
        <RentalTerm
          setFromPackageChoose={setFromPackageChoose}
          setCurrent={setCurrent}
          data={data}
          selected={term}
          setSelected={setTerm}
          device={device}
          setDevice={setDevice}
          setData={setData}
          addon={addon}
          setAddon={setAddon}
          allPlans={allPlans}
          plans={plans}
          filteredPlans={filteredPlans}
          basePrice={basePrice}
          myRef={myRef}
          URLparam={URLparam}
        />
      ),
      id: 3,
    },
    {
      title: "We’ll Get In Touch",
      content: (
        <GetInTouch
          setCurrent={setCurrent}
          fromPackageChoose={fromPackageChoose}
        />
      ),
      id: 4,
    },
    {
      title: "Payment",
      content: (
        <Payment
          setCurrent={setCurrent}
          data={data}
          filteredPlans={filteredPlans}
          summary={summary}
          zipLocation={zipLocation1}
          setData={setData}
          setZipLocation1={setZipLocation1}
          myRef={myRef}
          URLparam={URLparam}
        />
      ),
      id: 5,
    },
    {
      title: "Pickup",
      content: (
        <Pickup
          setCurrent={setCurrent}
          data={data}
          zipLocation={zipLocation}
          setData={setData}
        />
      ),
      id: 6,
    },
    {
      title: "InValidZipCode",
      content: <InValidZipCode setCurrent={setCurrent} />,
      id: 7,
    },

    {
      title: "Congratulation",
      content: (
        <Congratulation
          zipLocation={zipLocation}
          data={data}
          setCurrent={setCurrent}
        />
      ),
      id: 8,
    },
    {
      title: "InValidZipCode",
      content: <InValidZipCode setCurrent={setCurrent} current={5} />,
      id: 9,
    },
    {
      title: "Delivery",
      content: (
        <Delivery
          setCurrent={setCurrent}
          zipLocation={zipLocation}
          delivery={delivery}
          setDelivery={setDelivery}
          data={data}
          setData={setData}
        />
      ),
      id: 10,
    },
    {
      title: "TemporaryLastStep",
      content1: <TemporaryLastStep />,
      id: 99,
    },
  ];

  // useEffect(() => {}, []);
  return (
    <>
      <div ref={myRef}></div>
      {steps[current].content}
    </>
  );
};

export default CheckoutContent;
