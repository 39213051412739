import { useState } from "react";
import Styles from "./Pickup.module.scss";
import {
  StepsTitle,
  Wrapper,
  PickupCard,
  PrevButton,
  NextButton,
} from "../componentsCheckout";
import { SelectCard, Typography } from "../../../components";
import PickUpInfoText from "./PickUpInfoText";
import DatePicker from "../../../components/datePicker";
import { InlineWidget } from "react-calendly";

const Pickup = ({ setCurrent, data, zipLocation, setData }) => {
  const [selected, setSelected] = useState(null);

  return (
    <div className={Styles.pickupWrapper}>
      <div className={Styles.titlecontent}>
        <PrevButton
          onClick={() => {
            setData({ ...data, plan: null });
            setCurrent(3);
          }}
        />
        <StepsTitle text="Pickup Date & Time" />
      </div>

      <Wrapper>
        {/* <InlineWidget url="https://calendly.com/wheels-nyc-rentals" /> */}

        <PickupCard zipLocation={zipLocation} />
        <PickUpInfoText />
        <div>
          <section>
            <Typography
              text="Select a Day"
              strong
              className={Styles.sectionTitle}
            />
            <div className={Styles.DatePickerContainerWrapper}>
              <DatePicker
                open
                showToday={false}
                className={Styles.mainClass}
                style={{ position: "relative", top: "-37px" }}
                dropdownClassName={Styles.dropdownClassName}
              />
            </div>
          </section>
        </div>
        <div>
          <section className={Styles.section}>
            <Typography
              text="Select a Time"
              strong
              className={Styles.sectionTitle}
            />

            <SelectCard
              id={1}
              selected={selected}
              onSelect={setSelected}
              priceText="9:00 AM"
              price="2 spots left"
              strong={false}
              firstTextClassName={Styles.firstTextClassName}
              secondTextClassName={Styles.secondTextClassName}
            />
            <SelectCard
              id={2}
              selected={selected}
              onSelect={setSelected}
              priceText="9:30 AM"
              price="2 spots left"
              strong={false}
              firstTextClassName={Styles.firstTextClassName}
              secondTextClassName={Styles.secondTextClassName}
            />
            <SelectCard
              id={3}
              selected={selected}
              onSelect={setSelected}
              priceText="10:00 AM"
              price="2 spots left"
              strong={false}
              firstTextClassName={Styles.firstTextClassName}
              secondTextClassName={Styles.secondTextClassName}
            />
            <SelectCard
              id={4}
              selected={selected}
              onSelect={setSelected}
              priceText="10:30 AM"
              price="2 spots left"
              strong={false}
              firstTextClassName={Styles.firstTextClassName}
              secondTextClassName={Styles.secondTextClassName}
            />
            <SelectCard
              id={5}
              selected={selected}
              onSelect={setSelected}
              priceText="11:00 AM"
              price="2 spots left"
              strong={false}
              firstTextClassName={Styles.firstTextClassName}
              secondTextClassName={Styles.secondTextClassName}
            />
            <SelectCard
              id={6}
              selected={selected}
              onSelect={setSelected}
              priceText="11:30 AM"
              price="2 spots left"
              strong={false}
              firstTextClassName={Styles.firstTextClassName}
              secondTextClassName={Styles.secondTextClassName}
            />
          </section>
        </div>
      </Wrapper>
      <NextButton
        htmlType="submit"
        type="primary"
        title="Continue"
        onClick={() => setCurrent(5)}
      />
    </div>
  );
};

export default Pickup;
