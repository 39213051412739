import { useState, useEffect } from "react";
import { FloatLabel, Typography, Button } from "../../../components";
import { Input, Form, Select, message } from "antd";
import styled from "styled-components";
import { NextButton, PaymentDetail, PrevButton } from "../componentsCheckout";
import { StepsTitle, Wrapper } from "../componentsCheckout";
import { useForm } from "antd/lib/form/Form";
import zipCode from "../../../ZipCode";
import Styles from "./Payment.module.scss";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { DELIVERY_OPTIONS } from "../../../plans";
import api from "../../../api";
import { schedules } from "../../../schedule";
import {
  customerReferralId,
  getRewardfulCoupon,
  getPostalCode,
} from "../../../helpers";
import PlaceAutocompleteInput from "../../../components/googleAddress/GoogleAddress";
import usa from "../../../usa.json";
import ReactGA from "react-ga";

const stripeStyle = {
  base: {
    fontFamily: "Public Sans",
    fontSize: "14px",
    fontWeight: "500",
    color: "#777",
    "::placeholder": {
      color: "#333",
      fontWeight: "500",
    },
  },
};

const Payment = ({
  setCurrent,
  data,
  summary,
  setData,
  myRef,
  URLparam,
  filteredPlans,
}) => {
  ReactGA.initialize("G-V6QDG3Z3E9");
  const GOOGLE_MAPS_KEY = "AIzaSyAhNnpKjb5NiaxDVf5NNXKZxAOD7sKVx8M";
  const mapUrl = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&libraries=places`;

  let params = new URLSearchParams(window.location.search);
  const stripe = useStripe();
  const elements = useElements();

  const [addressBilling, setAddressBilling] = useState(
    data.address_billing || ""
  );
  const [apartmentBilling, setApartmentBilling] = useState(
    data.apartment_billing || ""
  );
  const [cityBilling, setCityBilling] = useState(data.city_billing || "");
  const [stateBilling, setStateBilling] = useState(data.state_billing || "");
  const [zipBilling, setZipBilling] = useState(data.zipCode_billing || "");

  // const [addressDelivery, setAddressDelivery] = useState(
  //   data.address_delivery || ""
  // );
  // const [apartmentDelivery, setApartmentDelivery] = useState(
  //   data.apartments_delivery || ""
  // );

  // const [cityDelivery, setCityDelivery] = useState(data.city_delivery || "");
  // const [stateDelivery, setStateDelivery] = useState(
  //   data.state_delivery || "Alabama"
  // );
  // const [zipDelivery, setZipDelivery] = useState(data.zipCode_delivery || "");

  const [cardName, setCardName] = useState(data.cardName || "");
  const [cardNumber, setCardNumber] = useState(data.cardNumber || "");
  const [cardValidDate, setCardValidDate] = useState(data.cardValidDate || "");
  const [cardCVC, setCardCVC] = useState(data.cardCVC || "");

  const [promoCode, setPromoCode] = useState(data.promoCode || "");

  const [calendlyLink, setCalendlyLink] = useState("");

  const [loading, setLoading] = useState(false);
  const [promoCodeData, setPromoCodeData] = useState("");
  const [googleAddress, setGoogleAddress] = useState("");

  const [locationValue, setLocationValue] = useState("");

  const isDefaultFlow = !params.get("rental_type");
  const isEdenredOnly = params.get("rental_type")?.toLowerCase() === "edenred";
  const isGoogleOnly = params.get("rental_type")?.toLowerCase() === "google";

  const [form] = useForm();
  const { Option } = Select;
  const validZipCodeBilling = zipBilling in zipCode;
  // const validZipCodeDelivery = zipDelivery in zipCode;
  const onFinish = async (values) => {
    await form.validateFields().then(() => {
      setData({
        ...data,
        address_billing: values?.address_billing,
        apartment_billing: values?.apartment_billing,
        city_billing: values?.city_billing,
        state_billing: values?.state_billing,
        zipCode_billing: values?.zipCode_billing,
        address_delivery: values?.address_delivery,
        apartments_delivery: values?.apartments_delivery,
        city_delivery: values?.city_delivery,
        state_delivery: values?.state_delivery,
        zipCode_delivery: values?.zipCode_delivery,
        cardName: values?.cardName,
        cardNumber: values?.cardNumber,
        cardValidDate: values?.cardValidDate,
        cardCVC: values?.cardCVC,
      });
    });
    onsubmit();
    // if (data.packageType === "select") {
    //   if (data?.isBilling === true) {
    //     validZipCodeDelivery ? onsubmit() : setCurrent(9);
    //   } else {
    //     validZipCodeBilling && validZipCodeDelivery
    //       ? onsubmit()
    //       : setCurrent(9);
    //   }
    // } else {
    //   validZipCodeBilling ? onsubmit() : setCurrent(9);
    // }
    // validZipCodeBilling ? onsubmit() : setCurrent(9);
  };
  const onsubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { error, token } = await stripe.createToken(
      cardNumberElement,
      CardExpiryElement,
      CardExpiryElement
    );

    if (error) {
      message.error({
        content: "Invalid card !",
        className: "custom-class",
        style: {
          marginTop: "5vh",
        },
        duration: 5,
      });
    } else {
      setLoading(true);
      if (!data?.user?.user) {
        setLoading(false);
        message.error({
          content: "Invalid Info !",
          className: "custom-class",
          style: {
            marginTop: "5vh",
          },
          duration: 5,
        });
      }
      const order = await api.submitOrder({
        address: addressBilling?.label || addressBilling /*|| addressDelivery*/,
        address2: null,
        city: cityBilling /*|| cityDelivery */,
        state: stateBilling /*|| stateDelivery*/,
        zipCode: String(data?.zipCode) /*zipBilling */ /*|| zipDelivery*/,
        customerId: data.userId,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        planId: data?.plan?.id,
        quantity: 1,
        rentalType: data.packageType,
        token: token.id,
        user: data?.user?.user,
        deliveryType: data.deliveryChoose,
        chargeDeposit: isEdenredOnly || isGoogleOnly ? false : true,
        isDefaultFlow,
        promoCode: promoCodeData ? promoCodeData?.id : "",
        coupon: getRewardfulCoupon(),
        customerReferralId: customerReferralId(),
      });

      await api.getOrderStatus(order).then((res) => {
        if (res === "order_failed") {
          message.error({
            content: "Order failed!",
            className: "custom-class",
            style: {
              marginTop: "5vh",
            },
            duration: 5,
          });

          setLoading(false);
        } else if (res === "subscribed") {
          const trackData = {
            token: process.env.REACT_APP_KLAVIO,
            event: `New order`,
            customer_properties: {
              $email: data.email,
              $first_name: data.firstName,
              $last_name: data.lastName,
              $zip: data?.zipCode /*zipBilling*/,
              $phone_number: data?.phoneNumber,
            },
            properties: {
              type: data.packageType,
              value: `$${(data?.plan?.amount_cents / 100).toFixed(2)}`,
              address: addressBilling,
              orderId: order?.id,
              planId: data?.plan?.id,
              customerId: data.userId,
            },
          };
          const trackJSON = JSON.stringify(trackData);
          const options = {
            method: "POST",
            headers: {
              Accept: "text/html",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              data: trackJSON,
            }),
          };
          fetch("https://a.klaviyo.com/api/track", options)
            .then((response) => response.json())
            .catch((err) => console.error(err));

          setLoading(false);
          window.fbq("track", "Subscribe", {
            value: Number(data?.plan?.amount_cents) / 100,
            currency: "USD",
          });

          window.fbq("track", "Purchase", {
            content_type: data?.plan?.type,
            value: Number(data?.plan?.amount_cents) / 100,
            currency: "USD",
          });

          window.gtag("event", "conversion", {
            send_to: "AW-593270032/Im9VCNb63d0BEJCq8poC",
            value: Number(data?.plan?.amount_cents) / 100,
            currency: "USD",
          });

          ReactGA.pageview("Purchase");
          message.success({
            content: "Order completed",
            className: "custom-class",
            style: {
              marginTop: "5vh",
            },
            duration: 5,
          });

          window.location.href = calendlyLink;
          window.open(calendlyLink, "_blank");
        }
      });
    }
  };
  const calendlyCheck = () => {
    let calendlyOption = null;

    const schedule = schedules.filter((schedule) => {
      return schedule.serviceCenterAddress === data?.address?.Address;
    });
    calendlyOption = schedule[0];

    const calendlyUrl =
      data.packageType === "choice"
        ? calendlyOption?.warehouse
        : calendlyOption?.delivery;
    setCalendlyLink(calendlyUrl);
  };
  // useEffect(() => {
  //   if (data.packageType == "select") {
  //     return null;
  //   } else {
  //     if (zipBilling in zipCode) {
  //       let zipCodeFormList = zipCode[zipBilling][0];
  //       if (zipCodeFormList) {
  //         setData({ ...data, address: zipCodeFormList });
  //       }
  //     } else {
  //       return;
  //     }
  //   }
  // }, [zipBilling]);

  // useEffect(() => {
  //   if (zipDelivery in zipCode) {
  //     let zipCodeFormList = zipCode[zipDelivery][0];
  //     if (zipCodeFormList) {
  //       setData({ ...data, address: zipCodeFormList });
  //     }
  //   } else {
  //     return;
  //   }
  // }, [zipDelivery]);

  const setLocationValues = async (locValues) => {
    setLocationValue(locValues);
    const { terms } = locValues.value;
    const postalCode = await getPostalCode(locValues.value.place_id);
    form.setFieldsValue({
      address_billing: setAddressInputValue(locValues) || "",
      city_billing: terms[terms.length - 3]?.value || "",
      state_billing: terms[terms.length - 2]?.value || "",
      zipCode_billing: postalCode || "",
    });
    if (locValues && terms[terms.length - 3]?.value) {
      setCityBilling(terms[terms.length - 3]?.value);
    } else {
      setCityBilling(null);
    }
    if (locValues && terms[terms.length - 2]?.value) {
      setStateBilling(terms[terms.length - 2]?.value);
    }
    if (locValues && postalCode) {
      setZipBilling(postalCode);
    } else {
      setZipBilling(null);
    }
    if (locValues) {
      setAddressBilling(locValues);
    }
  };
  const setAddressInputValue = (locValue) => {
    const terms = locValue?.value?.terms || "";

    return terms
      ? terms.length > 4
        ? `${terms[0].value}, ${terms[1].value}`
        : terms[0].value
      : addressBilling;
  };

  const addressText = setAddressInputValue(locationValue);

  const addressInputValue = addressText
    ? {
        label: addressText,
        value: addressText,
      }
    : "";
  // useEffect(() => {
  //   if (zipBilling in zipCode) {
  //     let zipCodeFormList = zipCode[zipBilling][0];
  //     if (zipCodeFormList) {
  //       setData({ ...data, address: zipCodeFormList });
  //     }
  //   } else {
  //     return;
  //   }
  // }, [zipBilling]);

  useEffect(() => {
    form.setFieldsValue(data);
  }, []);

  return (
    <div className={Styles.paymentWrapper}>
      <div className={Styles.titlecontent}>
        <PrevButton
          onClick={() => {
            URLparam === "google"
              ? setCurrent(0)
              : URLparam === "ubereats"
              ? setCurrent(10)
              : setCurrent(3);
          }}
        />
        <StepsTitle text="Payment" />
      </div>
      <Form onFinish={onFinish} form={form}>
        <Wrapper>
          <PaymentDetail
            plan={summary}
            className={Styles.paymentDetailWrapper}
            packageType={data.packageType}
            data={data}
            promoCodeData={promoCodeData}
            URLparam={URLparam}
          />
          {/* <DeliveryInformation
          zipLocation={data?.address}
          packageType={data.packageType}
          address={addressDelivery}
          infoTitle={
            data.packageType === "choice"
              ? `Service Center Pickup – ${
                  data?.address?.State === undefined ? "" : data?.address?.State
                }`
              : "Delivery Information"
          }
        /> */}

          <>
            {/* {data.packageType === "select" && (
              <section style={{ marginBottom: "3.5rem" }}>
                <Typography
                  text="Delivery Address"
                  strong
                  className={Styles.sectionSubtitle}
                />

                <FloatLabel
                  label="Address"
                  name="address_delivery"
                  value={addressDelivery}
                  className={Styles.inputWrapper}
                >
                  <Form.Item
                    name="address_delivery"
                    rules={[
                      {
                        required: true,
                        message: "Please input your address!",
                      },
                    ]}
                  >
                    <Input
                      value={addressDelivery}
                      onChange={(e) => setAddressDelivery(e.target.value)}
                      className={Styles.input}
                      autoComplete="new-password"
                    />
                  </Form.Item>
                </FloatLabel>

                <FloatLabel
                  label="Suite or Apartment #"
                  name="apartments_delivery"
                  value={apartmentDelivery}
                  className={Styles.inputWrapper}
                >
                  <Form.Item name="apartments_delivery">
                    <Input
                      value={apartmentDelivery}
                      onChange={(e) => setApartmentDelivery(e.target.value)}
                      className={Styles.input}
                      autoComplete="new-password"
                    />
                  </Form.Item>
                </FloatLabel>

                <div className={Styles.addressInfo}>
                  <FloatLabel
                    label="City"
                    name="city_delivery"
                    value={cityDelivery}
                    className={Styles.inputWrapper}
                  >
                    <Form.Item
                      name="city_delivery"
                      rules={[
                        {
                          required: true,
                          message: "Please input your city !",
                        },
                      ]}
                    >
                      <Input
                        value={cityDelivery}
                        onChange={(e) => setCityDelivery(e.target.value)}
                        className={Styles.input}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </FloatLabel>

                  <FloatLabel
                    label="State"
                    name="state_delivery"
                    value={stateDelivery}
                    className={Styles.inputWrapper}
                  >
                    <div
                 
                    >
                      <Form.Item
                        name="state_delivery"
                        rules={[
                          {
                            required: true,
                            message: "Please input your state !",
                          },
                        ]}
                      >
                        <Select
                          onChange={(e) => setStateDelivery(e)}
                          className={Styles.input}
                          value={stateDelivery}
                        >
                          {usaStates.map((s, idx) => (
                            <Option value={s.name} key={idx}>
                              {s.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </FloatLabel>
                  <FloatLabel
                    label="Zip Code"
                    name="zipCode_delivery"
                    value={zipDelivery}
                    className={Styles.inputWrapper}
                  >
                    <Form.Item
                      name="zipCode_delivery"
                      rules={[
                        {
                          required: true,
                          message: "Please input zip code",
                        },
                        {
                          pattern: /^[0-9]{4,5}$/,
                          message: "4-5 digits",
                        },
                      ]}
                    >
                      <Input
                        controls={false}
                        value={zipDelivery}
                        onChange={(e) => setZipDelivery(e.target.value)}
                        className={Styles.input}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </FloatLabel>
                </div>
                <Form.Item name="isBilling" valuePropName="checked">
                  <Checkbox
                    defaultChecked={data.isBilling}
                    onChange={(event) => {
                      setData({ ...data, isBilling: event.target.checked });
                    }}
                  >
                    <span className={Styles.checkbox}>
                      Billing address is same as delivery address
                    </span>
                  </Checkbox>
                </Form.Item>
              </section>
            )} */}

            {(!data.isBilling || data.packageType === "choice") && (
              <section>
                <Typography
                  text="Billing Address"
                  strong
                  className={Styles.sectionSubtitle}
                />

                <FloatLabel
                  label="Address"
                  name="address_billing"
                  value={addressBilling}
                  className={Styles.inputWrapper}
                >
                  <Form.Item
                    name="address_billing"
                    rules={[
                      {
                        required: true,
                        message: "Address is required !",
                      },
                      // () => ({
                      //   validator(_) {
                      //     if (locationValue?.value?.terms) {
                      //       // if (locationValue?.value?.terms.length <= 4) {
                      //       //   return Promise.reject(
                      //       //     new Error("Street number is required")
                      //       //   );
                      //       // }
                      //       return Promise.resolve();
                      //     }
                      //     return Promise.reject(
                      //       new Error("Address is required")
                      //     );
                      //   },
                      // }),
                    ]}
                  >
                    <PlaceAutocompleteInput
                      locationValue={addressInputValue}
                      setLocationValues={setLocationValues}
                    />
                    {/* <Input
                      value={addressBilling}
                      onChange={(e) => setAddressBilling(e.target.value)}
                      className={Styles.input}
                      autoComplete="new-password"
                    /> */}
                  </Form.Item>
                </FloatLabel>

                <FloatLabel
                  label="Suite or Apartment #"
                  name="apartment_billing"
                  value={apartmentBilling}
                  className={Styles.inputWrapper}
                >
                  <Form.Item name="apartment_billing">
                    <Input
                      value={apartmentBilling}
                      onChange={(e) => setApartmentBilling(e.target.value)}
                      className={Styles.input}
                      autoComplete="new-password"
                    />
                  </Form.Item>
                </FloatLabel>

                <div className={Styles.addressInfo}>
                  <FloatLabel
                    label="City"
                    name="city_billing"
                    value={cityBilling}
                    className={Styles.inputWrapper}
                  >
                    <Form.Item
                      name="city_billing"
                      rules={[
                        {
                          required: true,
                          message: "City is required",
                        },
                      ]}
                    >
                      <Input
                        // value={cityBilling}
                        onChange={(e) => setCityBilling(e.target.value)}
                        className={Styles.input}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </FloatLabel>

                  <FloatLabel
                    label="State"
                    name="state_billing"
                    value={stateBilling}
                    className={Styles.inputWrapper}
                  >
                    <div
                    //  style={{ marginTop: "15px" }}
                    >
                      <Form.Item
                        name="state_billing"
                        rules={[
                          {
                            required: true,
                            message: "State is required",
                          },
                        ]}
                      >
                        <Select
                          onChange={(e) => {
                            setStateBilling(e);
                          }}
                          className={Styles.input}
                          // value={stateBilling}
                        >
                          {usa.map((s, idx) => (
                            <Option value={s.Code} key={idx}>
                              {s.State}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </FloatLabel>
                  <FloatLabel
                    label="Zip Code"
                    name="zipCode_billing"
                    value={zipBilling}
                    className={Styles.inputWrapper}
                  >
                    <Form.Item
                      name="zipCode_billing"
                      rules={[
                        {
                          required: true,
                          message: "Zip code is required",
                        },
                        {
                          pattern: /^[0-9]{4,5}$/,
                          message: "4-5 digits",
                        },
                      ]}
                    >
                      <Input
                        controls={false}
                        // value={zipBilling}
                        onChange={(e) => setZipBilling(e.target.value)}
                        className={Styles.input}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </FloatLabel>
                </div>
              </section>
            )}

            <section>
              <Typography
                text="Card Information"
                strong
                className={Styles.sectionSubtitle}
              />
              <FloatLabel
                label="Name on Card"
                name="cardName"
                value={cardName}
                className={Styles.inputWrapper}
              >
                <Form.Item
                  name="cardName"
                  rules={[
                    {
                      required: true,
                      message: "Name on card is required",
                    },
                  ]}
                >
                  <Input
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    className={Styles.input}
                    autoComplete="new-password"
                  />
                </Form.Item>
              </FloatLabel>
              <div className={Styles.paymentWrapper}>
                <CardNumberElement
                  options={{
                    style: stripeStyle,
                  }}
                  className={Styles.strapInput}
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e)}
                />

                <div className={Styles.paymentInfo}>
                  <CardExpiryElement
                    options={{ style: stripeStyle }}
                    className={Styles.strapInput}
                    value={cardValidDate}
                    onChange={(e) => setCardValidDate(e)}
                  />

                  <CardCvcElement
                    options={{ style: stripeStyle }}
                    className={Styles.strapInput}
                    value={cardCVC}
                    onChange={(e) => setCardCVC(e)}
                  />
                </div>
                <span className={Styles.paymentWrapperText}>
                  Powered by Stripe
                </span>
              </div>
            </section>

            <section>
              <Typography
                text="Referral or Promo Code"
                strong
                className={Styles.sectionSubtitle}
              />
              <div className={Styles.promoCodeWrapper}>
                <FloatLabel
                  label="Referral or Promo Code"
                  name="promoCode"
                  value={promoCode}
                  className={Styles.inputWrapper}
                >
                  <Input
                    value={promoCode}
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                    }}
                    className={Styles.input}
                  />
                </FloatLabel>
                <Button
                  title="Apply"
                  disabled={promoCode.length < 1}
                  className={Styles.promoCodeApplyButton}
                  onClick={async () => {
                    const promo = await api
                      .checkPromoCodeValidity(promoCode)
                      .then((res) => {
                        if (res?.success === false) {
                          message.error({
                            content: "Invalid promo code !",
                            className: "custom-class",
                            style: {
                              marginTop: "5vh",
                            },
                            duration: 5,
                          });
                          setPromoCodeData(null);
                        }
                        if (res?.data?.id) {
                          message.success({
                            content: "Promo code was applied !",
                            className: "custom-class",
                            style: {
                              marginTop: "5vh",
                            },
                            duration: 5,
                          });
                          setPromoCodeData(res.data);
                        }
                      });
                  }}
                />
              </div>
            </section>
          </>
        </Wrapper>
        <NextButton
          htmlType="submit"
          type="primary"
          title="Submit Payment & Schedule"
          loading={loading}
          onClick={calendlyCheck}
          text={
            <>
              <p style={{ color: "#333" }}>
                Your subscription will auto-renew after the term.
              </p>

              {URLparam !== "google" && URLparam !== "edenred" && (
                <div
                  className={Styles.buttonWrapperText}
                  style={{ color: "#333" }}
                >
                  You will be charged a{" "}
                  {URLparam === "ubereats" ? "$25" : "$50"} security deposit per
                  device. It will be refunded when your device(s) are returned.
                </div>
              )}
            </>
          }
        />
      </Form>
    </div>
  );
};

export default Payment;
