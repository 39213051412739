import { Row } from "antd";
import Styles from "./Pickup.module.scss";
import { Typography } from "../../../components";

const PickUpInfoText = () => {
  return (
    <>
      <Typography
        text="Thank you for Choosing Wheels."
        className={Styles.subtitle}
      />
      <Row>
        <Typography
          text="Please select a date and time that fits your schedule."
          className={Styles.subtitleInfo}
        />
      </Row>
      <Row>
        <Typography
          text="In order to receive service, you must:"
          className={Styles.subtitleInfo}
        />
      </Row>

      <Row>
        <ul>
          <li>
            <b>Download the App,</b> and create your account.
          </li>
          <li>
            <b>Bring a Drivers License or Government Issued Photo ID,</b> it is
            required and must match the name on the account.
          </li>
          <li>
            <b>Wear a Mask,</b> for the safety of you and others.
          </li>
        </ul>
      </Row>
    </>
  );
};

export default PickUpInfoText;
