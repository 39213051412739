import { Typography } from "../../../components";
import {
  StepsTitle,
  Wrapper,
  PrevButton,
  NextButton,
} from "../componentsCheckout";
import inTouchIMG from "../../../assets/inTouch.png";
import Styles from "./InValidZipCode.module.scss";

const InValidZipCode = ({ setCurrent, current = 0 }) => {
  return (
    <div className={Styles.inValidZipCodeWrapper}>
      <div className={Styles.titlecontent}>
        <PrevButton onClick={() => setCurrent(current)} />
        <StepsTitle text="Stay In Touch" />
      </div>
      <Wrapper>
        <div className={Styles.inTouchIMGWrapper}>
          <img
            src={inTouchIMG}
            alt="InTouchIMG"
            className={Styles.inTouchIMG}
          />
        </div>
        <Typography
          text="Wheels is not yet available in your area. We will notifiy you when service is available in your city."
          className={Styles.subtitle}
        />
      </Wrapper>
      <NextButton
        htmlType="submit"
        type="primary"
        title="Done"
        onClick={() => setCurrent(current)}
      />
    </div>
  );
};

export default InValidZipCode;
