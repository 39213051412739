import { ApolloClient, InMemoryCache } from "@apollo/client";
import { gql } from "@apollo/client/core";

class Api {
  constructor() {
    this.client = new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL_URI,
      //  ||
      // "https://selected-urchin-31.hasura.app/v1/graphql",
      // "https://charmed-impala-43.hasura.app/v1/graphql",
      cache: new InMemoryCache(),
    });
  }

  async checkPromoCodeValidity(code) {
    const url = `${process.env.REACT_APP_BACKEND_URI_PROMO_TEST}/check_promo?code=${code}`;
    // const url = `https://selected-urchin-31.hasura.app/v1/graphql/check_promo?code=${code}`;
    const response = await fetch(url, { mode: "cors" }).then((res) =>
      res.json()
    );

    return response;
  }

  async triggerPhoneNumberValidation(phoneNumber) {
    const response = await this.client.mutate({
      mutation: gql`
        mutation TriggerPhoneNumberValidation($phone_number: String!) {
          trigger_phone_number_validation(phone_number: $phone_number) {
            success
            errors {
              code
              message
            }
          }
        }
      `,
      variables: {
        phone_number: phoneNumber,
      },
    });

    const data = response.data.trigger_phone_number_validation;
    return data.success;
  }

  async verifyPhoneValidation(phoneNumber, code, isForCustomerPortal) {
    const response = await this.client.mutate({
      mutation: gql`
        mutation VerifyPhoneValidation(
          $phone_number: String!
          $code: String!
          $customer_portal: Boolean!
        ) {
          verify_phone_number_validation(
            code: $code
            phone_number: $phone_number
            customer_portal: $customer_portal
          ) {
            user {
              email
              id
              first_name
              jwt
              last_name
              phone_number
              charge_stripe_customer_id
              stripe_customer_portal_link
            }
            success
            errors {
              code
              message
            }
          }
        }
      `,
      variables: {
        phone_number: phoneNumber,
        code: code,
        customer_portal: isForCustomerPortal || false,
      },
    });

    const data = response.data.verify_phone_number_validation;
    return {
      success: data.success,
      user: data.user,
    };
  }

  async getPlans() {
    const response = await this.client.query({
      query: gql`
        query GetPlans {
          plans(order_by: { amount_cents: asc }) {
            id
            amount_cents
            day_rate_cents
            description
            is_best_value
            is_most_popular
            is_multimonth
            monthly_amount_cents
            name
            rental_type
            # delivery_type
            should_display
            term
            type
            cargo_rack
            upgrade_bike
            package_type
          }
        }
      `,
    });

    return response.data.plans;
  }

  async insertCustomer(customer) {
    const response = await this.client.mutate({
      mutation: gql`
        mutation InsertCustomersOne(
          $email: String!
          $first_name: String!
          $last_name: String!
          $phone_number: String!
        ) {
          insert_customers_one(
            object: {
              email: $email
              first_name: $first_name
              last_name: $last_name
              phone_number: $phone_number
            }
          ) {
            id
          }
        }
      `,
      variables: {
        email: customer.email,
        first_name: customer.firstName,
        last_name: customer.lastName,
        phone_number: customer.phoneNumber,
      },
    });

    return response.data.insert_customers_one;
  }

  async submitOrder(order) {
    const variables = {
      address: order.address,
      address_unit_number: order.address2 || "",
      address_city: order.city,
      address_state: order.state,
      address_zip: order.zipCode,
      bike_rental_type: order.rentalType,
      customer_id: order.customerId,
      email: order.email,
      first_name: order.firstName,
      last_name: order.lastName,
      phone_number: order.phoneNumber,
      plan_id: order.planId,
      quantity: order.quantity,
      token: order.token,
      user_id: order.user && order.user.id,
      wheels_user_info: order.user,
      delivery_type: order.deliveryType,
      charge_deposit: order.chargeDeposit,
      is_default_flow: order.isDefaultFlow,
      promo_code: order.promoCode,
      coupon: order.coupon,
      customer_referral_id: order.customerReferralId,
    };

    const response = await this.client.mutate({
      mutation: gql`
        mutation InsertOrderOne(
          $email: String!
          $first_name: String!
          $last_name: String!
          $phone_number: String!
          $customer_id: uuid!
          $plan_id: uuid!
          $token: String!
          $user_id: uuid!
          $wheels_user_info: jsonb!
          $address: String!
          $address_unit_number: String
          $address_city: String
          $address_state: String
          $address_zip: String
          $bike_rental_type: String!
          $quantity: Int!
          $delivery_type: String
          $charge_deposit: Boolean!
          $is_default_flow: Boolean!
          $promo_code: String!
          $coupon: jsonb
          $customer_referral_id: uuid
        ) {
          insert_order(
            objects: [
              {
                email: $email
                first_name: $first_name
                last_name: $last_name
                phone_number: $phone_number
                customer_id: $customer_id
                plan_id: $plan_id
                token: $token
                user_id: $user_id
                wheels_user_info: $wheels_user_info
                address: $address
                address_unit_number: $address_unit_number
                address_city: $address_city
                address_state: $address_state
                address_zip: $address_zip
                bike_rental_type: $bike_rental_type
                quantity: $quantity
                delivery_type: $delivery_type
                charge_deposit: $charge_deposit
                is_default_flow: $is_default_flow
                promo_code: $promo_code
                coupon: $coupon
                customer_referral_id: $customer_referral_id
              }
            ]
          ) {
            affected_rows
            returning {
              id
            }
          }
        }
      `,
      variables: variables,
    });

    return response.data.insert_order.returning[0];
  }

  async getOrderStatus(order) {
    let status = null;

    while (!status || status === "pending") {
      const response = await this.client.query({
        fetchPolicy: "no-cache",
        query: gql`
          query GetOrder($id: uuid!) {
            order_by_pk(id: $id) {
              status
            }
          }
        `,
        variables: {
          id: order.id,
        },
      });

      status = response.data.order_by_pk.status;

      if (status === "pending") {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }

    return status;
  }
}

export default new Api();
