import classNames from "classnames";
import Styles from "./StepsButton.module.scss";

import { Button } from "../../../../components";

const StepsButton = ({
  title,
  text,
  infoText,
  handleTextClick,
  className,
  ...rest
}) => {
  return (
    <div className={classNames(Styles.ButtonWrapperMain, className)}>
      <div className={Styles.ButtonWrapper}>
        <Button title={title} {...rest} />
        {text && <div onClick={handleTextClick}>{text}</div>}
      </div>
      {infoText && <p className={Styles.infoText}>{infoText}</p>}
    </div>
  );
};

export default StepsButton;
