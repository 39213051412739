import { useState, useEffect } from "react";
import { Input, Form, InputNumber, Select } from "antd";
import ReactGA from "react-ga";

import { FloatLabel } from "../../../components";
import Styles from "./UserInformation.module.scss";
import { NextButton, StepsTitle } from "../componentsCheckout";
import { useForm } from "antd/lib/form/Form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import zipCode from "../../../ZipCode";
import api from "../../../api";
import Checkbox from "../../../components/checkbox";

const UserInformation = ({ setCurrent, setData, data, setZipLocation, filteredPlans, URLparam }) => {
  ReactGA.initialize("G-V6QDG3Z3E9");
  const [firstName, setFirstName] = useState(data.firstName || "");
  const [lastName, setLastName] = useState(data.lastName || "");
  const [email, setEmail] = useState(data.email || "");
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber);
  const [zip, setZip] = useState(data.zipCode || "");
  const [checkBoxValid, setCheckBoxValid] = useState(true);

  const [form] = useForm();

  const validZipCode = zip in zipCode;
  const onFinish = (values) => {
    setData({
      ...data,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      zipCode: values.zipCode,
    });
    validZipCode ? setCurrent(1) : setCurrent(7);
    validZipCode && api.triggerPhoneNumberValidation(`+${phoneNumber}`);
    if (validZipCode) {
      const userId = api
        .insertCustomer({
          firstName,
          lastName,
          email,
          phoneNumber: `+${phoneNumber}`,
        })
        .then((res) => setData({ ...data, userId: res.id }));
    }
  };

  const a = () => {
    ReactGA.pageview("UserInfoPage");
    setData({
      ...data,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      zipCode: zip,
      plan: filteredPlans[1] || filteredPlans[0],
      planTemp: filteredPlans[1] || filteredPlans[0],
    });
  };

  useEffect(() => {
    if (zip in zipCode) {
      let zipCodeFormList = zipCode[zip][0];
      setZipLocation(zipCodeFormList);
      if (zipCodeFormList) {
        setData({ ...data, address: zipCodeFormList });
      }
    } else {
      return;
    }
  }, [zip]);
  useEffect(() => {
    form.setFieldsValue(data);
  }, []);

  return (
    <Form onFinish={onFinish} form={form}>
      <div className={Styles.userInformation}>
        <StepsTitle text="Your Information" style={{ display: "inline-block", marginLeft: "10%" }} />
        <div className={Styles.formWrapper}>
          {/* <Typography text="Personal Detail" className={Styles.subTitle} /> */}

          <FloatLabel label="First Name" name="firstName" value={firstName} className={Styles.inputWrapper}>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "First name is required",
                },
              ]}
            >
              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={Styles.input}
                autoComplete="new-password"
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Last Name" name="lastName" value={lastName} className={Styles.inputWrapper}>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Last name is required",
                },
              ]}
            >
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={Styles.input}
                autoComplete="new-password"
              />
            </Form.Item>
          </FloatLabel>
          <FloatLabel label="Email Address" name="emailAddress" value={email} className={Styles.inputWrapper}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Email address is required",
                },
              ]}
            >
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={Styles.input}
                autoComplete="new-password"
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel name="phoneNumber" value={phoneNumber} className={Styles.inputWrapper}>
            <Form.Item
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Phone number is required",
                },
                {
                  pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                  message: "Please input valid phone number!",
                },
              ]}
            >
              <PhoneInput
                country={"us"}
                containerClass={Styles.containerClass}
                buttonClass={Styles.buttonClass}
                inputClass={Styles.inputClass}
                dropdownClass={Styles.dropdownClass}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e)}
              />
            </Form.Item>
          </FloatLabel>

          <FloatLabel label="Zip Code" name="zipCode" value={zip} className={Styles.inputWrapper}>
            <Form.Item
              name="zipCode"
              rules={[
                {
                  required: true,
                  message: "Zip code is required",
                },
                {
                  pattern: /^[0-9]{4,5}$/,
                  message: "4-5 digits",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                value={zip}
                onChange={(e) => setZip(e)}
                className={Styles.input}
                autoComplete="new-password"
              />
            </Form.Item>
          </FloatLabel>

          {URLparam === "google" && (
            <div className="agreement">
              <FloatLabel className={Styles.inputWrapper}>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value) {
                          setCheckBoxValid(true);
                          return Promise.resolve();
                        }
                        setCheckBoxValid(false);
                        return Promise.reject(new Error("Acknowledgement and consent are required"));
                      },
                    }),
                  ]}
                >
                  {" "}
                  <div className={!checkBoxValid && Styles.checkBoxError}>
                    <div className={Styles.agreementWrapper}>
                      <div>
                        <Checkbox color_type={"blue"}></Checkbox>
                      </div>
                      <div className={Styles.agreement}>
                        I acknowledge that a Google badge is required at the time of pick up/delivery and I consent to
                        Wheels sharing usage data with Google for the purpose of reimbursement
                      </div>
                    </div>
                  </div>
                </Form.Item>
              </FloatLabel>
            </div>
          )}
        </div>
      </div>

      <NextButton
        htmlType="submit"
        type="primary"
        title="Continue"
        onClick={a}
        infoText={
          <>
            By clicking 'Continue' below I agree to Wheels{" "}
            <a href="https://takewheels.com/terms-of-service/" target="_blank" rel="noreferrer">
              Terms of Use
            </a>
            ,{" "}
            <a href="https://takewheels.com/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy{" "}
            </a>
            and{" "}
            <a href="https://takewheels.com/long-term-rental-agreements/" target="_blank" rel="noreferrer">
              Rental Agreement{" "}
            </a>
            <span style={{ color: "#333" }}>
              By clicking 'Continue', I consent to receive both marketing and non-marketing text messages, including by
              automated dialer, to the number I provided, and I understand that I can opt out by replying 'STOP'.
            </span>
          </>
        }
      />
    </Form>
  );
};

export default UserInformation;
