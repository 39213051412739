import { Row } from "antd";
import classNames from "classnames";
import { Typography } from "..";
import Styles from "./CheckBoxCard.module.scss";

const CheckBoxCard = ({
  selected,
  onSelect,
  priceText,
  price,
  className,
  strong = true,
  firstTextClassName,
  secondTextClassName,
  data,
}) => {
  const myClassName = selected
    ? Styles.selectCardWrapperActives
    : Styles.selectCardWrapper;

  let addonPrice;
  if (data.addonTypePrice === "5") {
    addonPrice = `$ ${data.addonTypePrice}.00 / ${
      data.addonTypePrice * 6
    } Days`;
  } else if (data.addonTypePrice === "15") {
    addonPrice = `$ ${data.addonTypePrice}.00 / ${
      data.addonTypePrice * 6
    } Days`;
  }
  return (
    <div
      className={classNames(myClassName, className)}
      onClick={() => onSelect(!selected)}
    >
      <Row justify="space-between">
        <Typography
          text={priceText}
          strong={strong}
          className={classNames(Styles.cardFirsRow, firstTextClassName)}
        />
        <Typography
          text={addonPrice}
          strong={strong}
          className={classNames(Styles.cardFirsRow, secondTextClassName)}
        />
      </Row>
    </div>
  );
};

export default CheckBoxCard;
