import api from "./api";

export const PLAN_TYPES = {
  ONE_MONTH: "oneMonth",
  THREE_MONTH: "threeMonth",
  SIX_MONTH: "sixMonth",
  WEEKLY: "weekly",
  YEARLY: "yearly",
};

export const RENTAL_TYPES = {
  DELIVERY: "delivery",
  PRIVATE: "private",
};

export const DELIVERY_OPTIONS = {
  DELIVERY: "delivery",
  WAREHOUSE: "warehouse",
};

export const PACKAGE_OPTIONS = {
  WHEELS_CHOICE: "choice",
  WHEELS_SELECT: "select",
};

export class Plans {
  constructor() {
    this._plans = [];
    this.isLoaded = false;
  }

  async load() {
    this._plans = await api.getPlans();
    this.isLoaded = true;
  }

  selected(state) {
    const {
      isDefaultFlow,
      rentalType,
      wheelsPackage,
      upgradeBike,
      includeCargoRack,
    } = state;
    let plans;

    if (isDefaultFlow) {
      plans = this._plans.filter(
        (p) =>
          p.package_type === wheelsPackage &&
          p.upgrade_bike === upgradeBike &&
          p.cargo_rack === includeCargoRack
      );
    } else {
      plans = this.visible(state);
    }
    return plans.find((p) => {
      return p.type === state.planType;
    });
  }

  visible({
    rentalType,
    isDeliveryOnly,
    isUberOnly,
    isLifemartOnly,
    isTaskRabbitOnly,
    isFavorOnly,
    isFavorExtraOnly,
    isEdenredOnly,
    isGoogleOnly,
    isDefaultFlow,
    wheelsPackage,
  }) {
    let predicate;

    if (isUberOnly) {
      predicate = (p) => {
        return (
          p.name === "1 Week Special Price" ||
          p.name === "1 Monthly Special Price"
        );
      };
    } else if (isLifemartOnly) {
      predicate = (p) => {
        return (
          p.name === "LifeMart Monthly Plan" && p.rental_type === rentalType
        );
      };
    } else if (isTaskRabbitOnly) {
      predicate = (p) => {
        return p.name === "TaskRabbit Monthly" && p.rental_type === rentalType;
      };
    } else if (isFavorOnly) {
      predicate = (p) => {
        return p.name === "Favor Monthly";
      };
    } else if (isFavorExtraOnly) {
      predicate = (p) => {
        return p.name === "Favor Extra Monthly";
      };
    } else if (isDeliveryOnly) {
      predicate = (p) => {
        return (
          p.should_display &&
          p.rental_type === RENTAL_TYPES.DELIVERY &&
          p.name !== "LifeMart Monthly Plan" &&
          p.name !== "TaskRabbit Monthly" &&
          p.name !== "Favor Monthly" &&
          p.name !== "Favor Extra Monthly"
        );
      };
    } else if (isEdenredOnly) {
      predicate = (p) => {
        return (
          p.name === "Edenred Monthly" ||
          p.name === "Edenred 3 months" ||
          p.name === "Edenred 6 months" ||
          p.name === "Edenred Yearly"
        );
      };
    } else if (isDefaultFlow) {
      predicate = (p) => {
        const rType =
          wheelsPackage === PACKAGE_OPTIONS.WHEELS_CHOICE
            ? RENTAL_TYPES.PRIVATE
            : RENTAL_TYPES.DELIVERY;

        return (
          p.should_display &&
          p.rental_type === rType &&
          p.name !== "LifeMart Monthly Plan" &&
          p.name !== "TaskRabbit Monthly" &&
          p.name !== "Favor Monthly" &&
          p.name !== "Favor Extra Monthly" &&
          p.name !== "Edenred Monthly" &&
          p.name !== "Edenred 3 months" &&
          p.name !== "Edenred 6 months" &&
          p.name !== "Edenred Yearly" &&
          p.name !== "Google Monthly"
        );
      };
    } else if (isGoogleOnly) {
      predicate = (p) => {
        return p.name === "Google Monthly";
      };
    } else {
      predicate = (p) => {
        return (
          p.should_display &&
          p.rental_type === rentalType &&
          p.name !== "LifeMart Monthly Plan" &&
          p.name !== "TaskRabbit Monthly" &&
          p.name !== "Favor Monthly" &&
          p.name !== "Favor Extra Monthly" &&
          p.name !== "Edenred Monthly" &&
          p.name !== "Edenred 3 months" &&
          p.name !== "Edenred 6 months" &&
          p.name !== "Edenred Yearly" &&
          p.name !== "Google Monthly"
        );
      };
    }

    return this._plans.filter(predicate).reverse();
  }

  basePrice(state) {
    const plans = this.visible(state);

    let plan = plans.find((p) => {
      return p.type === PLAN_TYPES.WEEKLY;
    });

    if (plan) {
      return plan.monthly_amount_cents;
    }

    // HACK(ibash) this causes all the discounts to be < 0, which makes
    // discounts hidden in the ui
    return 1;
  }

  withBasket({ planType }) {
    return this._plans.find((p) => {
      return (
        p.should_display &&
        p.type === planType &&
        p.rental_type === RENTAL_TYPES.DELIVERY
      );
    });
  }

  withoutBasket({ planType }) {
    return this._plans.find((p) => {
      return (
        p.should_display &&
        p.type === planType &&
        p.rental_type === RENTAL_TYPES.PRIVATE
      );
    });
  }
}
