// import { Col, Row } from "antd";
import Styles from "./Congratulation.module.scss";
import {
  NextButton,
  PrevButton,
  StepsTitle,
  Wrapper,
} from "../componentsCheckout";
import { Typography } from "../../../components";
// import playMaket from "../../../assets/playMaket.png";
// import appStore from "../../../assets/appStore.png";
// import inTouchIMG from "../../../assets/inTouch.png";
import congrats from "../../../assets/congrats.png";

const Congratulation = ({
  // zipLocation,
  data,
  setCurrent,
}) => {
  return (
    <div className={Styles.congratulationWrapper}>
      <div className={Styles.titlecontent}>
        <StepsTitle
          text="Congratulations!"
          style={{ display: "inline-block", marginLeft: "10%" }}
        />
        {/* <PrevButton
          onClick={() => {
            setCurrent(5);
          }}
        /> */}
      </div>

      <Wrapper>
        <div className={Styles.inTouchIMGWrapper}>
          <img src={congrats} alt="congrats" className={Styles.inTouchIMG} />
        </div>
        <Typography
          text="Your Wheels device order was placed."
          className={Styles.subtitle}
        />
        <Typography
          text="Thank you for your Wheels device subscription. Get ready to ride."
          className={Styles.info}
        />
        <Typography
          text="PLEASE CLICK TO SCHEDULE."
          className={Styles.scheduleTitle}
        />
        {data.calendlyLink && (
          <NextButton
            title="Schedule"
            href={data.calendlyLink}
            target="_blank"
          />
        )}
        {/* <>
        <Typography
          text="Download our app today!"
          className={Styles.download}
        />

        <Typography
          text={
            data.packageType === "choice"
              ? `Service Center Pickup – ${zipLocation?.City}`
              : "Delivery Information"
          }
          className={Styles.serviceCenter}
        />
        <div className={Styles.deliveryInfoBox}>
          {data.packageType === "choice" ? (
            <Row>
              <Col span={4}>Pickup:</Col>
              <Col span={20}>{zipLocation?.Address}</Col>
            </Row>
          ) : (
            <Row>
              <Col span={4}>Dropoff:</Col>
              <Col span={20}>
                {data.zipCode_delivery} {data.state_delivery}{" "}
                {data.city_delivery}, {data.address_delivery},{" "}
                {data.apartments_delivery}
              </Col>
            </Row>
          )}
          <Row>
            <Col span={4}>Date:</Col>
            <Col span={20}>May 22, 2022</Col>
          </Row>
          <Row>
            <Col span={4}>Time:</Col>
            <Col span={20}>10:00 AM</Col>
          </Row> 
        </div>
        {data.calendlyLink && (
          <NextButton
            title="Schedule"
            href={data.calendlyLink}
            target="_blank"
          />
        )}
        <div className={Styles.iconContainer}>
          <a
            href="https://apps.apple.com/us/app/wheels-ride-safe/id1436122530"
            target="_blank"
          >
            <img
              src={appStore}
              alt="play Market"
              className={Styles.icons}
              style={{ padding: "0.9rem" }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.getwheelsapp.wheels&hl=en&gl=US"
            target="_blank"
          >
            <img src={playMaket} alt="play Market" className={Styles.icons} />{" "}
          </a>
        </div>
        </> */}
      </Wrapper>
    </div>
  );
};

export default Congratulation;
