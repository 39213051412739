import Styles from "./PrevButton.module.scss";

import { Button } from "../../../../components";
import { LeftOutlined } from "@ant-design/icons";

const PrevButton = ({ ...rest }) => {
  return (
    <Button
      {...rest}
      title={<LeftOutlined className={Styles.icon} />}
      className={Styles.prevButton}
      style={{ textAlign: "left" }}
    />
  );
};

export default PrevButton;
