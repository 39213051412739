import {
  Typography,
  CheckBoxCard,
  RentalChoose,
  DeviceTypeSelect,
} from "../../../components";
import { StepsTitle, NextButton, PrevButton } from "../componentsCheckout";
import { Wrapper } from "../componentsCheckout";
import ReactGA from "react-ga";
import Styles from "./RentalTerm.module.scss";

const RentalTerm = ({
  setFromPackageChoose,
  setCurrent,
  data,
  setData,
  selected,
  setSelected,
  device,
  setDevice,
  addon,
  setAddon,
  filteredPlans,
  basePrice,
  myRef,
  URLparam,
}) => {
  const executeScroll = () => myRef.current.scrollIntoView({ block: "end" });
  ReactGA.initialize("G-V6QDG3Z3E9");
  return (
    <div className={Styles.rentalTermWrapper}>
      <div className={Styles.titlecontent}>
        <PrevButton
          onClick={() => {
            setData({
              ...data,
              plan: null,
              deviceTypePrice: "10",
              addonTypePrice: "5",
            });
            URLparam === "google" ||
            URLparam === "ubereats" ||
            URLparam === "edenred"
              ? setCurrent(0)
              : setCurrent(2);

            setSelected(1);
          }}
        />
        <StepsTitle text="Rental Term" />
      </div>

      <Wrapper>
        <Typography
          text="Choose Rental Term"
          strong
          className={Styles.subtitle}
        />

        {filteredPlans
          ?.slice(0)
          ?.reverse()
          ?.map((plan, idx) => (
            <div
              onClick={() => {
                setData({
                  ...data,
                  rentalTermType: plan.type,
                  deviceTypePrice: idx === 0 ? "10" : "30",
                  addonTypePrice: idx === 0 ? "5" : "15",
                  plan: plan,
                  planTemp: plan,
                });
              }}
              key={idx}
            >
              <RentalChoose
                id={idx + 1}
                selected={selected}
                onSelect={setSelected}
                className={Styles.cardWrapepr}
                plan={plan}
                packageType={data.packageType}
                basePrice={basePrice}
                URLparam={URLparam}
              />
            </div>
          ))}
        {URLparam === "ubereats" || URLparam === "edenred" ? (
          <></>
        ) : (
          <>
            <Typography
              text="Select Device"
              strong
              className={Styles.subtitle}
            />
            <div
              onClick={() => {
                setData({ ...data, deviceType: "Wheel 2.0" });
              }}
            >
              <DeviceTypeSelect
                id={1}
                selected={device}
                onSelect={setDevice}
                priceText="Wheels 2.0"
                className={Styles.cardWrapepr}
                data={data}
                price={false}
              />
            </div>

            <div
              onClick={() => {
                setData({
                  ...data,
                  deviceType: "Wheel 3.0",
                });
              }}
            >
              <DeviceTypeSelect
                id={2}
                selected={device}
                onSelect={setDevice}
                priceText="Wheels 3.0"
                className={Styles.cardWrapepr}
                data={data}
                price={true}
                subtitle="Upgraded motor for better performance."
              />
            </div>

            <Typography text="Add-ons" strong className={Styles.subtitle} />

            <div
              onClick={() => {
                setData({ ...data, addontype: !addon });
              }}
            >
              <CheckBoxCard
                id={1}
                selected={addon}
                onSelect={setAddon}
                priceText="Cargo Rack"
                data={data}
                className={Styles.cardWrapepr}
              />
            </div>
          </>
        )}
      </Wrapper>
      <NextButton
        htmlType="submit"
        type="primary"
        title="Continue"
        onClick={() => {
          window.fbq("track", "RentalTerm");
          ReactGA.pageview("RentalTerm");
          if (data.plan) {
            executeScroll();
            URLparam === "ubereats" ? setCurrent(10) : setCurrent(5);
          } else {
            setData({ ...data, plan: data.planTemp });
            executeScroll();
            URLparam === "ubereats" ? setCurrent(10) : setCurrent(5);
          }
        }}
        text={
          <>
            <div style={{ color: "#333" }}>
              Looking to lease multiple devices?{" "}
            </div>

            <a
              onClick={() => {
                window.fbq("track", "MultipleDevices");
                setFromPackageChoose(false);
                setCurrent(4);
              }}
            >
              Get in contact with a Wheels Specialist.
            </a>
          </>
        }
      />
    </div>
  );
};

export default RentalTerm;
