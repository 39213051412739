import { useEffect, useState } from "react";
import Styles from "./CheckoutLayout.module.scss";
import CheckoutContent from "../../Checkout/checkoutContent/CheckoutContent";
import coverIMG from "../../assets/cover2.png";
import logo from "../../assets/logo.svg";
import phone from "../../assets/phone.png";
import classNames from "classnames";
import { Plans, PLAN_TYPES, RENTAL_TYPES, DELIVERY_OPTIONS } from "../../plans";

const CheckoutLayout = () => {
  const [current, setCurrent] = useState(0);
  const [filteredPlans, setFilteredPlans] = useState();
  const [basePrice, setBasePrice] = useState();
  const [summary, setSummary] = useState();
  const [zipLocation, setZipLocation] = useState();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    zipCode: "",
    userId: "",
    packageType: "choice",
    rentalTermType: "30_Days",
    deviceType: "Wheel 2.0",
    deviceTypePrice: "10",
    addontype: null,
    addonTypePrice: "5",
    plan: "",
    address_billing: "",
    apartments_billing: "",
    city_billing: "",
    state_billing: "",
    zipCode_billing: "",
    isBilling: null,
    address_delivery: "",
    apartments_delivery: "",
    city_delivery: "",
    state_delivery: "",
    zipCode_delivery: "",
    cardName: "",
    cardNumber: "",
    cardValidDate: "",
    cardCVC: "",
    promoCode: "",
    user: "",
    calendlyLink: "",
    testLink: "",
    addressBilling: "",
    addressDelivey: "",
    deliveryChoose: "warehouse",

    planTemp: null,
  });

  const plans = new Plans();

  let params = new URLSearchParams(window.location.search);
  const isUberOnly = params.get("rental_type")?.toLowerCase() === "ubereats";
  const isLifemartOnly =
    params.get("rental_type")?.toLowerCase() === "lifemart";
  const isTaskRabbitOnly =
    params.get("rental_type")?.toLowerCase() === "taskrabbit";
  const isFavorOnly = params.get("rental_type")?.toLowerCase() === "favor";
  const isFavorExtraOnly =
    params.get("rental_type")?.toLowerCase() === "favor_extra";
  const isEdenredOnly = params.get("rental_type")?.toLowerCase() === "edenred";
  const isGoogleOnly = params.get("rental_type")?.toLowerCase() === "google";
  const isPromoOnly = params.get("rental_type") === "promo";
  const isDeliveryOnly =
    isUberOnly || isTaskRabbitOnly || isFavorOnly || isFavorExtraOnly;

  const shouldDisplayPromo =
    params.get("rental_type") === "promo" || !params.get("rental_type");

  const isDefaultFlow = !params.get("rental_type");

  const test = {
    // contact step
    firstName: "",
    lastName: "",
    email: "",
    region: "US",
    phoneNumber: "",
    customerId: "",
    isGoogleEmployee: false,

    // verify number step
    code: "",
    user: null,
    hasVerificationError: false,

    wheelsPackage: data.packageType,
    includeCargoRack: false,
    upgradeBike: false,

    // initially selected plan
    planType: PLAN_TYPES.ONE_MONTH,
    rentalType: isDeliveryOnly ? RENTAL_TYPES.DELIVERY : RENTAL_TYPES.PRIVATE,
    deliveryOption: DELIVERY_OPTIONS.WAREHOUSE,
    isDeliveryOnly: isDeliveryOnly,
    isUberOnly: isUberOnly,
    isLifemartOnly: isLifemartOnly,
    isTaskRabbitOnly: isTaskRabbitOnly,
    isFavorOnly: isFavorOnly,
    isFavorExtraOnly: isFavorExtraOnly,
    isEdenredOnly: isEdenredOnly,
    isGoogleOnly: isGoogleOnly,
    isPromoOnly: isPromoOnly,
    isDefaultFlow: isDefaultFlow,
    shouldDisplayPromo: shouldDisplayPromo,

    // how many step
    quantity: 1,

    // address step
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",

    // stripe step
    promoCode: "",
    nameOnCard: "",
    zipCodeOnCard: "",
    billingAddress: "",
    cityOnCard: "",
    stateOnCard: "",
    hasStripeError: false,
    hasPromoCodeError: false,
    isLoading: false,
    validCoupon: null,
  };

  const p = async () => {
    await plans.load();
  };

  useEffect(async () => {
    await p();
    const visible = await plans.visible(test);
    const basePrice = await plans.basePrice(test);
    const summary = await plans.selected(test);
    await setFilteredPlans(visible);
    await setBasePrice(basePrice);
    await setSummary(summary);
  }, [data.packageType]);

  useEffect(() => {}, [data]);

  return (
    <div
      className={classNames(Styles.checkoutWrapper, {
        [Styles.checkoutWrapperCongrats]: current === 8,
      })}
    >
      <>
        {current !== 8 ? (
          <div style={{ background: "#111" }}>
            {current === 8 ? (
              <div className={Styles.left}>
                <img src={phone} alt="phone" className={Styles.phone} />
              </div>
            ) : (
              <>
                <img
                  src={coverIMG}
                  alt="CoverIMG"
                  className={
                    current === 6
                      ? Styles.coverIMGForDatePicker
                      : Styles.coverIMG
                  }
                />

                <a
                  href="https://takewheels.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={logo} alt="Logo" className={Styles.logo} />
                </a>
              </>
            )}
          </div>
        ) : (
          <>
            {" "}
            {current === 8 ? (
              <div className={Styles.left}>
                <img src={phone} alt="phone" className={Styles.phone} />
              </div>
            ) : (
              <img
                src={coverIMG}
                alt="CoverIMG"
                className={
                  current === 6 ? Styles.coverIMGForDatePicker : Styles.coverIMG
                }
              />
            )}
          </>
        )}
      </>
      <div
        className={classNames(Styles.CheckoutProcess, {
          [Styles.CheckoutProcessForDatePicker]: current === 6,
          [Styles.CheckoutProcessCongrats]: current === 8,
        })}
      >
        <CheckoutContent
          setCurrent={setCurrent}
          current={current}
          plans={plans}
          filteredPlans={filteredPlans}
          basePrice={basePrice}
          data={data}
          setData={setData}
          summary={summary}
          zipLocation={zipLocation}
          setZipLocation={setZipLocation}
        />
      </div>
    </div>
  );
};

export default CheckoutLayout;
