import { useState, memo } from "react";
import classNames from "classnames";
import Styles from "./FloatLabel.module.scss";

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value, className } = props;

  const labelClass =
    focus || (value && value.length !== 0)
      ? classNames(Styles.label, Styles.labelFloat)
      : classNames(Styles.label);

  return (
    <div
      className={classNames(Styles.floatLabel, className)}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

export default memo(FloatLabel);
