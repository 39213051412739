import { Button } from "antd";
import classNames from "classnames";

import Styles from "./Button.module.scss";

const CutomButton = ({ type = "primary", className, title, ...rest }) => {
  return (
    <Button
      type={type}
      {...rest}
      className={classNames(Styles.buttonWrapper, className)}
    >
      {title}
    </Button>
  );
};

export default CutomButton;
