import { Row } from "antd";
import classNames from "classnames";
import { Typography } from "..";
import Styles from "./SelectCard.module.scss";

const SelectCard = ({
  id,
  selected,
  onSelect,
  priceText,
  price,
  subtitle,
  arrText,
  className,
  strong = true,
  firstTextClassName,
  secondTextClassName,
  zipLocation,
  showAddress = false,
}) => {
  const myClassName =
    id === selected
      ? Styles.selectCardWrapperActives
      : Styles.selectCardWrapper;

  return (
    <div
      className={classNames(myClassName, className)}
      onClick={() => onSelect(id)}
    >
      <Row justify="space-between">
        <Typography
          text={priceText}
          strong={strong}
          className={classNames(Styles.cardFirsRow, firstTextClassName)}
        />
        <Typography
          text={price}
          strong={strong}
          className={classNames(Styles.cardFirsRow, secondTextClassName)}
        />
      </Row>
      {subtitle && <Typography text={subtitle} className={Styles.subtitle} />}
      {arrText &&
        arrText.map((text, idx) => {
          return (
            <div className={Styles.cardbody} key={idx}>
              <i
                className={classNames(
                  "fas fa-check-circle ",
                  Styles.circleStyle
                )}
              ></i>
              <div className={Styles.text}>
                {text}{" "}
                {showAddress && idx === 0 && (
                  <div className={Styles.location}>
                    Located at {zipLocation?.Address}
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SelectCard;
