import { PrevButton, StepsTitle, NextButton } from "../componentsCheckout";
import { Wrapper } from "../componentsCheckout";
import Styles from "./Delivery.module.scss";
import { DeliveryChoose } from "../../../components";
import classNames from "classnames";

const Delivery = ({ zipLocation, setCurrent, delivery, setDelivery, setData, data }) => {
  return (
    <div className={Styles.deliveryChooseWrapper}>
      <div className={Styles.titlecontent}>
        <PrevButton onClick={() => setCurrent(3)} />
        <StepsTitle text="Delivery" />
      </div>
      <Wrapper>
        <div
          onClick={() => {
            setData({ ...data, deliveryChoose: "warehouse" });
          }}
        >
          <DeliveryChoose
            selected={delivery}
            onSelect={setDelivery}
            priceText="Service Center Pickup"
            id={1}
            price="Free"
            subtitle={[
              <>
                <span>Pick up your device at our service center located at:</span>
              </>,

              <div>
                <b>{zipLocation?.Address}</b>
              </div>,
            ]}
          />
        </div>
        <div
          onClick={() => {
            setData({ ...data, deliveryChoose: "delivery" });
          }}
        >
          <DeliveryChoose
            selected={delivery}
            onSelect={setDelivery}
            priceText="Delivery"
            id={2}
            price="$ 19.99"
            subtitle="Your device will be delivered to your home address"
          />
        </div>
      </Wrapper>
      <NextButton htmlType="submit" type="primary" title="Continue" onClick={() => setCurrent(5)} />
    </div>
  );
};

export default Delivery;
