import { Row } from "antd";
import classNames from "classnames";
import { Typography } from "..";
import Styles from "./RentalChoose.module.scss";

const details = {
  yearly: {
    name: "365 days",
    period: "per 365 days",
  },
  threeMonth: {
    name: "90 days",
    period: "per 90 days",
  },
  sixMonth: {
    name: "180 days",
    period: "per 180 days",
  },
  oneMonth: {
    name: "30 days",
    period: "per 30 days",
  },
  weekly: {
    name: "7 days",
    period: "per 7 days",
  },
};

const RentalChoose = ({
  id,
  selected,
  onSelect,
  className,
  strong = true,
  firstTextClassName,
  secondTextClassName,
  plan,
  packageType,
  basePrice,
  URLparam,
}) => {
  const myClassName =
    id === selected
      ? Styles.selectCardWrapperActives
      : Styles.selectCardWrapper;

  if (!details[plan.type]) {
    // TODO(ibash) send a sentry error s.t. we know there's something missing
    // on the frontend
    return null;
  }

  // const { name, period } = details[plan.type];

  let periodType;
  let periodTypeEndenred;
  let periodTypeUberEats;
  let paymentPeriod;
  if (details[plan.type].period === "per 90 days") {
    periodType = `Wheels ${
      packageType.charAt(0).toUpperCase() + packageType.slice(1)
    } - 90 Days`;
    paymentPeriod = "90";
  } else if (details[plan.type].period === "per 30 days") {
    periodType = `Wheels ${
      packageType.charAt(0).toUpperCase() + packageType.slice(1)
    } - 30 Days`;
    paymentPeriod = "30";
  } else if (details[plan.type].period === "per 365 days") {
    periodType = `Wheels ${
      packageType.charAt(0).toUpperCase() + packageType.slice(1)
    } - 365 Days `;
    paymentPeriod = "365";
  } else if (details[plan.type].period === "per 180 days") {
    periodType = `Wheels ${
      packageType.charAt(0).toUpperCase() + packageType.slice(1)
    } - 180 Days `;
    paymentPeriod = "180";
  } else if (details[plan.type].period === "per 7 days") {
    periodType = `Wheels ${
      packageType.charAt(0).toUpperCase() + packageType.slice(1)
    } - 7 Days `;
    paymentPeriod = "7";
  }

  if (details[plan.type].period === "per 90 days") {
    periodTypeUberEats = `UberEats - 7 Days`;
    paymentPeriod = "90";
  } else if (details[plan.type].period === "per 30 days") {
    periodTypeUberEats = `UberEats - 30 Days`;
    paymentPeriod = "30";
  } else if (details[plan.type].period === "per 365 days") {
    periodTypeUberEats = `UberEats - 365 Days `;
    paymentPeriod = "365";
  } else if (details[plan.type].period === "per 180 days") {
    periodTypeUberEats = `UberEats - 180 Days `;
    paymentPeriod = "180";
  } else if (details[plan.type].period === "per 7 days") {
    periodTypeUberEats = `UberEats - 7 Days `;
    paymentPeriod = "7";
  }

  if (details[plan.type].period === "per 90 days") {
    periodTypeEndenred = `Edenred - 90 Days`;
    paymentPeriod = "90";
  } else if (details[plan.type].period === "per 30 days") {
    periodTypeEndenred = `Edenred - 30 Days`;
    paymentPeriod = "30";
  } else if (details[plan.type].period === "per 365 days") {
    periodTypeEndenred = `Edenred - 365 Days `;
    paymentPeriod = "365";
  } else if (details[plan.type].period === "per 180 days") {
    periodTypeEndenred = `Edenred - 180 Days `;
    paymentPeriod = "180";
  } else if (details[plan.type].period === "per 7 days") {
    periodTypeEndenred = `Edenred - 7 Days `;
    paymentPeriod = "7";
  }

  let price;
  if (plan.type === "oneMonth") {
    price = (plan.monthly_amount_cents / 100).toFixed(2);
  } else {
    price = (plan.amount_cents / 100).toFixed(2);
  }
  let oldPrice;
  let oldPriceEdenred;
  let oldPriceUberets;
  if (plan.name === "Choice 3 months") {
    oldPrice = 269.97;
  } else {
    oldPrice = 389.97;
  }

  if (plan.name === "Edenred 3 months") {
    oldPriceEdenred = 239.97;
  } else if (plan.name === "Edenred 6 months") {
    oldPriceEdenred = 479.94;
  } else if (plan.name === "Edenred Yearly") {
    oldPriceEdenred = 973.21;
  }

  if (plan.name === "1 Monthly Special Price") {
    oldPriceUberets = 119.96;
  }

  // const discount = Math.ceil(
  //   (100 * (basePrice - plan.monthly_amount_cents)) / basePrice
  // );

  // let discountEl;
  // if (discount <= 0) {
  //   // HACK(ibash) have a discount element for spacing, but make it invisible
  //   discountEl = `${(plan.monthly_amount_cents / 100).toFixed(2)}`;
  // } else {
  //   discountEl = `${discount}`;
  // }

  return (
    <div
      className={classNames(myClassName, className)}
      onClick={() => onSelect(id)}
    >
      <Row justify="space-between">
        <Typography
          text={
            URLparam === "edenred"
              ? periodTypeEndenred
              : URLparam === "ubereats"
              ? periodTypeUberEats
              : periodType
          }
          strong={strong}
          className={classNames(Styles.cardFirsRow, firstTextClassName)}
        />
        <Typography
          text={`$ ${price}`}
          strong={strong}
          className={classNames(Styles.cardFirsRow, secondTextClassName)}
        />
      </Row>
      <Row justify="space-between" align="bottom">
        {paymentPeriod && (
          <Typography
            text={`Payment will be processed every ${paymentPeriod} days.`}
            className={Styles.subtitle}
          />
        )}
        {/* {id === 2 && (
          <p className={Styles.discount}>${+discountEl + +price} </p>
        )} */}
        {id !== 1 && (
          <p className={Styles.discount}>
            $
            {URLparam === "edenred"
              ? oldPriceEdenred
              : URLparam === "ubereats"
              ? oldPriceUberets
              : oldPrice}{" "}
          </p>
        )}
      </Row>
    </div>
  );
};

export default RentalChoose;
