import { Typography } from "../../../components";
import { StepsTitle, Wrapper, PrevButton } from "../componentsCheckout";
import inTouchIMG from "../../../assets/inTouch.png";
import Styles from "./GetInTouch.module.scss";

const GetInTouch = ({ setCurrent, fromPackageChoose }) => {
  return (
    <div className={Styles.getInTouchWrapper}>
      <div className={Styles.titlecontent}>
        <PrevButton onClick={() => setCurrent(fromPackageChoose ? 2 : 3)} />
        <StepsTitle text="We’ll Get In Touch" />
      </div>

      <Wrapper>
        <div className={Styles.inTouchIMGWrapper}>
          <img
            src={inTouchIMG}
            alt="InTouchIMG"
            className={Styles.inTouchIMG}
          />
        </div>
        <Typography
          text="For custom orders, a Wheels service representative will get in contact with you."
          className={Styles.subtitle}
        />
      </Wrapper>
    </div>
  );
};

export default GetInTouch;
