import styled from "styled-components";
import { DatePicker as datepicker } from "antd";

const DatePicker = styled(datepicker)`
  background: red;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  //border-radius: 50px;
  //height: 44px;
  height: 35px;
  padding: 0 15px;
  font-weight: 300;
  // display: none;
  visibility: hidden;
  &:hover {
    border-color: #886cc0;
  }
  &:focus,
  &.ant-input-focused {
    border-color: #886cc0;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #886cc02e;
  }
  &.ant-picker-dropdown {
    display: inline-block !important;
  }
  .ant-picker-dropdown-placement-topLeft {
    display: inline-block !important;
  }
  button.ant-picker-header-prev-btn {
    position: absolute;
    left: -50px;
  }
`;

export default DatePicker;
