export const schedules = [
  {
    id: "ATX",
    serviceCenterAddress: "311 E St Elmo Rd Suite i Austin, TX 78745",
    warehouse: "https://calendly.com/rental_ops/atx-wheels-e-bike-appointment",
    delivery: "https://calendly.com/rental_ops/atx-wheels-delivery",
  },
  {
    id: "SD",
    serviceCenterAddress: "3877 Pacific Hwy San Diego, CA 92110",
    warehouse: "https://calendly.com/rental_ops/sd-wheels-e-bike-appointment",
    delivery: "https://calendly.com/rental_ops/sd-wheels-delivery",
  },
  {
    id: "LA",
    serviceCenterAddress: "5722 Jefferson Blvd Los Angeles, CA 90016",
    warehouse: "https://calendly.com/rental_ops/la-wheels-e-bike-appointment",
    delivery: "https://calendly.com/rental_ops/la-wheels-delivery",
  },
  {
    id: "NYC",
    serviceCenterAddress: "760 Humboldt St Brooklyn, NY 11222",
    warehouse:
      "https://calendly.com/wheels-nyc-rentals/nyc-wheels-e-bike-appointment",
    delivery: "https://calendly.com/rental_ops/nyc-wheels-delivery",
  },
  {
    id: "MIA",
    serviceCenterAddress: "2900 NW 112th Ave Unit D4 Doral, FL 33172",
    warehouse: "https://calendly.com/rental_ops/mia-wheels-e-bike-appointment",
    delivery: "https://calendly.com/rental_ops/mia-wheels-e-bike-appointment",
  },
  {
    id: "ORL",
    serviceCenterAddress: "5500 W Concord Ave Orlando, FL 32808",
    warehouse: "https://calendly.com/rental_ops/orl-wheels-e-bike-appointment",
    delivery: "https://calendly.com/rental_ops/orl-wheels-delivery",
  },
  {
    id: "SEA",
    serviceCenterAddress: "1757 First Avenue South Seattle, WA 98134",
    warehouse: "https://calendly.com/rental_ops/sea-wheels-e-bike-appointment",
    delivery: "https://calendly.com/rental_ops/sea-wheels-delivery",
  },
];
