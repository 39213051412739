import { Typography } from "../../../../components";
import Styles from "./StepsTitle.module.scss";

const StepsTitle = ({ text, className, ...props }) => {
  return (
    <div className={Styles.titleWrapper}>
      <Typography text={text} className={Styles.title} {...props} />
    </div>
  );
};

export default StepsTitle;
