import { Row } from "antd";
import classNames from "classnames";
import { Typography } from "../../../../components";
import Styles from "./PickupCard.module.scss";

const PickupCard = ({ className, zipLocation }) => {
  return (
    <div className={classNames(Styles.pickupCardWrapper, className)}>
      <Typography text="Order Detail" strong className={Styles.paymentTitle} />

      <Row className={Styles.firstRowWrapper}>
        <Typography
          text={`${zipLocation?.State} - Service center pickup`}
          className={Styles.cardFirsRow}
        />
      </Row>
      <Row>
        <Typography
          text={zipLocation?.Address}
          className={Styles.cardFirsRow}
        />
      </Row>
    </div>
  );
};

export default PickupCard;
