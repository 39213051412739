import "./App.scss";
import "antd/dist/antd.css";
import { CheckoutLayout } from "./layouts";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY
);

function App() {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            family: "Public Sans",
            cssSrc: "https://fonts.googleapis.com/css2?family=Public+Sans",
          },
        ],
      }}
    >
      <div className="App">
        <CheckoutLayout />
      </div>
    </Elements>
  );
}

export default App;
