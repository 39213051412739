import { Row } from "antd";
import classNames from "classnames";
import { Typography } from "..";
import Styles from "./DeliveryChoose.module.scss";

const DeliveryChoose = ({
  id,
  selected,
  onSelect,
  priceText,
  price,
  subtitle,
  className,
  strong = true,
  firstTextClassName,
  secondTextClassName,
  data,
}) => {
  const myClassName =
    id === selected
      ? Styles.selectCardWrapperActives
      : Styles.selectCardWrapper;

  return (
    <div
      className={classNames(myClassName, className)}
      onClick={() => onSelect(id)}
    >
      <Row justify="space-between">
        <Typography
          text={priceText}
          strong={strong}
          className={classNames(Styles.cardFirsRow, firstTextClassName)}
        />
        <Typography
          text={price}
          strong={strong}
          className={classNames(Styles.cardFirsRow, secondTextClassName)}
        />
      </Row>
      {subtitle && <Typography text={subtitle} className={Styles.subtitle} />}
    </div>
  );
};

export default DeliveryChoose;
