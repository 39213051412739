import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import styled from "styled-components";

const AutocompleteInputWrapper = styled.div`
  .input_parent {
    span {
      display: none;
    }
    > div:nth-child(2) {
      border: 1px solid #dadada;
      border-radius: 4px;
      height: 48px;
      width: 100%;
      font-size: 14px;
      background-color: #fff;
      font-weight: lighter;

      div[class*="placeholder"] {
        color: #fff;
        font-weight: lighter;
      }
      div[class*="singleValue"] {
        color: #777;
      }
    }
  }
`;
const PlaceAutocompleteInput = ({
  locationValue,
  setLocationValues,
  disabled,
}) => {
  return (
    <AutocompleteInputWrapper>
      <GooglePlacesAutocomplete
        // apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
        apiOptions={{ libraries: ["places"] }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ["usa"],
          },
          types: ["address"],
        }}
        selectProps={{
          className: "input_parent",
          value: locationValue,
          onChange: setLocationValues,
          isDisabled: disabled,
          placeholder: "Address",
        }}
      />
    </AutocompleteInputWrapper>
  );
};

export default PlaceAutocompleteInput;
